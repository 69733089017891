/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

export const About = () => (
     <Container>
          <Row>
               <Col>
                    <div className="pt-5">
                         
                         <h1>About me</h1>
                         
                    </div>
                    <div className="pt-3">
                    <p>
                    <h3>Software Engineer | CS Grad @ UB | Java | Spring Boot, Hibernate, RESTful APIs | MySQL, AWS, Docker | Scalable Backend Systems & API Development | Open to SWE Roles.</h3>
                         </p>
                         <p>
                         Experienced Software Engineer with a Master’s in Computer Science from the University at Buffalo. Specializes in backend development and API design using Java, Spring Boot, and Hibernate to build scalable, business-critical systems. Proficient in creating RESTful APIs, optimizing database schemas with MySQL, and implementing dynamic workflows to enhance system performance. Skilled in deploying applications using Docker, AWS Elastic Beanstalk, and CI/CD pipelines, with expertise in monitoring and improving system health using Spring Boot Actuator. Actively seeking opportunities in software engineering to leverage my expertise in backend systems and API development to deliver robust, scalable software solutions.
                    </p>
                    </div>
               </Col>
          </Row>
          <Row>
               <Col>
                    <div className="pt-5">
                         <h3>Skills</h3>
                    </div>
                    <div className="pt-3">
                    <ul>
                         <li>Java | Python</li>
                         <li>Spring Boot | Hibernate | Docker | JUnit | Git</li>
                         <li>MySQL | PostgreSQL | MongoDB</li>
                         <li>JIRA</li>
                         <li>Postman | REST API</li>
                         <li>Agile | Test-Driven Development (TDD) | Strategy Design Pattern</li>
                    </ul>
                    </div>
               </Col>
          </Row>
          <Row>
               <Col>
                    <div className="social-media">
                         <a href = "mailto: singh23@buffalo.edu" target="_blank" rel="noopener noreferrer"  className="pr-3" title="Send Email"><p>Email</p></a>
                         <a href="https://github.com/singhamritanshu" target="_blank" rel="noopener noreferrer" className="pr-3" title="Join on Github"><p>Github</p></a>
                         <a href="https://www.linkedin.com/in/singhamritanshu" target="_blank" rel="noopener noreferrer" className="pr-3" title="Join on Linkedin"><p>Linkedin</p></a>
                         
                    </div>
               </Col>
          </Row>
     </Container>
)   